import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { media } from "utils/Media";
import MainNav from "components/functional/mainNav";

import Layout from "components/layout";

import { StaticImage } from "gatsby-plugin-image";

const metaData = {
  title: "DEWYNTERS DIGEST February 2025 Edition: HERE COMES THE PUN  | D E W Y N T E R S",
  description:
    "Discover the power of puns in theatre marketing! From ‘Mind the Diors’ to ‘See it Yesterday,’ explore how clever wordplay grabs attention and sells tickets. Read more on Dewynters' latest insights.",
  image: "images/news/dew-digest-header.png",
};

const NewsBody = styled.section`
  width: 100%;

  .news {
    width: 85%;
    margin: var(--auto);
    max-width: 900px;
    @media ${media.sm} {
      padding-top: 2rem;
    }
  }

  .app-img {
    margin-bottom: 1rem;
  }
`;
const SingleNewsStyles = styled.article`
  position: relative;
  width: 100%;
  margin-bottom: 4rem;

  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }

  svg {
    width: 30px;
    height: 30px;
    fill: var(--cyan);
    margin-bottom: 0.5rem;
  }

  h1 {
    font-weight: 800;
    font-size: 1.5rem;

    span {
      color: var(--cyan);
    }
    @media ${media.md} {
      font-size: 33px;
    }
  }

  .body {
    margin-top: 1rem;
    .highlight {
      color: #09dbd8;
    }
    a {
      text-decoration: underline 2px;
      color: #09dbd8 !important;
      &:hover,
      &:active {
        transform: scale(1.5);
      }
    }
    .no-colour-change {
      color: #000 !important;
    }
    p {
      margin-bottom: 1rem;

      strong {
        font-weight: 900;
        font-family: var(--PoppinsBold);
      }

      span,
      a {
        color: var(--cyan);
        font-weight: 800;
      }
    }
  }

  @media ${media.sm} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
  @media ${media.xl} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
`;
const LatestNews = ({ data }) => {
  return (
    <Layout
      title={metaData.title}
      description={metaData.description}
      image={metaData.image}
    >
      <MainNav alt />
      <NewsBody>
        <div className="news">
          <SingleNewsStyles>
            <div className="header">
              <StaticImage
                src="../../images/news/dew-digest-header.png"
                alt="DEWYNTERS DIGEST February 2025 Edition: HERE COMES THE PUN  | D E W Y N T E R S"
                className="app-img"
              />
              <h1>HERE COMES THE PUN</h1>
            </div>
            <div className="body">
              <p>
                In early January, one of our ads for{" "}
                <em>The Devil Wears Prada: The Musical </em>caught fire on
                social media, prompting the{" "}
                <a href="https://www.linkedin.com/in/themattroach/">
                  original poster, Matt Roach
                </a>
                , to quip, &ldquo;whoever wrote this Hathaway with words!&rdquo;
                The ad, which appeared in a train carriage next to the doors,
                reminded viewers to &ldquo;Mind the Diors&rdquo; beside an image
                of the brand&rsquo;s iconic stiletto. The virality of this
                &ldquo;designer pun&rdquo; <em>(Mad Over Marketing) </em>is a
                delightful reminder of something we&rsquo;ve long believed
                &ndash; that when it comes to entertainment marketing, it pays
                to be&hellip; well, entertaining.&nbsp;&nbsp;
              </p>
              <p>
                In this age of the ever-decreasing attention span, we salute the
                awesomely efficient power of the humble pun. When well-crafted
                and well-placed, it can entertain in a way few other verbal
                constructions can, effectively conveying the fun that&rsquo;s
                waiting on the other side of a potential ticket
                sale.&nbsp;&nbsp;
              </p>
              <p>
                But unlike with steaks, well done puns are also rare &ndash;
                writing one is easier said than pun. Comedy writing in any form
                is hard. But it&rsquo;s often the glue that holds a creative
                idea together. That&rsquo;s the thing about great copy &ndash;
                it sticks.&nbsp;
              </p>
              <p>
                We&rsquo;ve always believed that <em>showing</em> people that a
                show or experience is fun makes for a more lasting impression
                than <em>telling</em> people it&rsquo;s fun. Puns help.{" "}
                <em>Back to the Future: The Musical</em>&rsquo;s &ldquo;Cool As
                Flux,&rdquo; for instance, hints at &ldquo;impressive fun for
                all ages&rdquo; without saying it&rsquo;s &ldquo;impressive fun
                for all ages.&rdquo; That campaign&rsquo;s calls to action
                include &ldquo;Don&rsquo;t walk. Don&rsquo;t run. McFly.&rdquo;
                and <em>&ldquo;</em>See it Yesterday.&rdquo; Admittedly, with
                well-known brands like <em>Devil Wears</em> <em>Prada </em>or{" "}
                <em>Back to the Future,</em> the need for less
                &ldquo;education&rdquo; upfront gives us the freedom to let
                loose in the comedy sandbox&hellip; which, unfortunately, is
                something we don&rsquo;t actually have in our office. Yet.&nbsp;
              </p>
              <div className="image app-img">
                <StaticImage
                  layout="fullWidth"
                  src="../../images/news/bttf.jpg"
                  alt="DEWYNTERS DIGEST February 2025 Edition: HERE COMES THE PUN  | D E W Y N T E R S"
                />
              </div>
              <p>
                Our friends at Jamie Wilson Productions (producers of Devil
                Wears Prada and Mrs. Doubtfire: The Musical) are true partners
                in pun-ditry. Our early ads for Doubtfire asked viewers,
                &ldquo;Who&rsquo;s Your Nanny?&rdquo; and encouraged them to
                &ldquo;Frock On.&rdquo; We&rsquo;ve juxtaposed Mrs. Doubtfire
                and Miranda Priestly within the same ad: &ldquo;Glam vs.
                Gran&rdquo;, &ldquo;The Queen of Fashion vs. The Queen of
                Tartan&rdquo;. We even connected JWP&rsquo;s production of
                Sister Act to Doubtfire with a &ldquo;Your Favourite Nun vs.
                Your Favourite Nan&rdquo; campaign. (Holy hit, was that a good
                one.) Okay, these last few aren&rsquo;t exactly puns&hellip; but
                they fit the billboard.&nbsp;
              </p>
              <div className="image app-img">
                <StaticImage
                  layout="fullWidth"
                  src="../../images/news/dfp.jpg"
                  alt="DEWYNTERS DIGEST February 2025 Edition: HERE COMES THE PUN  | D E W Y N T E R S"
                />
              </div>
              <p>
                In comedy, timing is everything. We&rsquo;ve often had a pun in
                the oven for months or even years before it&rsquo;s ready to
                serve. We couldn&rsquo;t wait for <em>Doubtfire</em> to run for
                a year so we could drop its &ldquo;First Nanniversary&rdquo;
                campaign. And since we first introduced her to the West End with
                a &ldquo;Hellooooooo!&rdquo; we knew she&rsquo;d someday have to
                say &ldquo;Toodle-ooooo!&rdquo; &nbsp;
              </p>
              <p>
                The festive season tends to be the most pun-derful time of the
                year. Time-strapped shoppers love a dose of efficient cheer,
                e.g. the joyful fun of <em>Mamma Mia!&rsquo;s</em>{" "}
                &ldquo;Snowing Me, Snowing You,&rdquo; &ldquo;Yulez-Vous&rdquo;
                ads&hellip; and snow many more.&nbsp;&nbsp;
              </p>
              <div className="image app-img">
                <StaticImage
                  layout="fullWidth"
                  src="../../images/news/mm.jpg"
                  alt="DEWYNTERS DIGEST February 2025 Edition: HERE COMES THE PUN  | D E W Y N T E R S"
                />
              </div>
              <p>
                Contextual advertising, so crucial in our attention economy,
                provides a golden opportunity to entertain with our ads. When
                context is King, copy can be Joker &ndash; wildly unpredictable,
                and whatever you need it to be. For Devil Wears Prada&rsquo;s
                bus-side ads, we found that the best way to channel Miranda
                Priestly&rsquo;s boss-bitch bluntness was not a pun but straight
                up contextual comedy: &ldquo;She doesn&rsquo;t take the
                bus.&rdquo; As the rest of that campaign told it, she also
                &ldquo;doesn&rsquo;t take the tube&rdquo; and
                &ldquo;doesn&rsquo;t do traffic.&rdquo; Miranda&rsquo;s
                &ldquo;direct, unsparing&rdquo; tone of voice also inspires
                every social post and community management reply extending her
                character beyond the stage into the larger brand journey.
                Possibly the only time we&rsquo;ve been able to give the sass
                back to our audiences!&nbsp;
              </p>
              <div className="image app-img">
                <StaticImage
                  layout="fullWidth"
                  src="../../images/news/social.png"
                  alt="DEWYNTERS DIGEST February 2025 Edition: HERE COMES THE PUN  | D E W Y N T E R S"
                />
              </div>
              <p>
                To know a good pun is to punderstand its limitations. In some
                cases, the nuances of a production&rsquo;s &ldquo;Brand
                Voice&rdquo; will lead us down other strategic paths. Our
                campaign for Magic Mike Live, for example, opts for
                &ldquo;Cheeky Sophistication&rdquo; &ndash; i.e. billboards with
                sexy guys telling drivers to keep their &ldquo;Eyes on the
                Road&rdquo; or ads in fashion magazines advising readers,
                &ldquo;Don&rsquo;t Go for the Costumes.&rdquo; A simple rhyme is
                the proven darling of our remarketing campaign: &ldquo;Stop
                Looking. Start Booking.&rdquo;&nbsp;&nbsp;
              </p>
              <div className="image app-img">
                <StaticImage
                  layout="fullWidth"
                  src="../../images/news/mml.jpg"
                  alt="DEWYNTERS DIGEST February 2025 Edition: HERE COMES THE PUN  | D E W Y N T E R S"
                />
              </div>
              <p>
                And of course when it comes to serious drama, funny
                doesn&rsquo;t fly. As much as we might <em>want </em>to
                advertise a production of <em>Uncle Vanya</em> as &ldquo;One to
                Checkov Your List&rdquo;, we&rsquo;ll save the zingers for the
                spoof.&nbsp;&nbsp;
              </p>
              <p>
                Yes, we&rsquo;re entertainment marketers. But we&rsquo;re also
                marketing entertainers. In show business, the consumer journey
                must be infused with the essence of the experience itself. Every
                satisfying burst of joy along the way &ndash; no matter how
                small &ndash; nudges people closer to booking
                tickets.&nbsp;&nbsp;
              </p>
              <p>
                We take pride in creating those priceless moments when the doom
                scrolling stops, the clouds of indifference part, and we think
                to ourselves&hellip; here comes the pun.&nbsp;&nbsp;
              </p>
              <p>And we say, it&rsquo;s all write.&nbsp;</p>
            </div>
            <p>
              <strong>
                <Link to="/opinion/">{`< Back`}</Link>
              </strong>
            </p>
          </SingleNewsStyles>
        </div>
      </NewsBody>
    </Layout>
  );
};

LatestNews.propTypes = {
  data: PropTypes.shape({
    News: PropTypes.any,
  }),
};

export default LatestNews;
